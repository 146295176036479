@use "constants/mixins.scss";
@use "constants/styles.scss";

.page404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #F6F6F6;
  position: relative;

  @include mixins.phones() {
    width: 100vw!important;
    max-width: 100vw!important;
    height: calc(100vh - 150px);

    & button {
      padding: 10px 20px;
    }
  }

  .image404 {
    margin-bottom: -40px;
  }

  .headerText {
    font-size: 133px;
  }

  .information {
    margin-top: -20px;
    margin-bottom: 30px;

    @include mixins.phones() {
      padding: 0 20px;
    }
  }

  .bad-url {
    margin: 10px 0;
  }
}
