@use "constants/styles.scss";

.filter-modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100000;

  .filter-modal-content {
    background-color: styles.$white;
    border-radius: 5px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .modal-header {
      padding: 10px 20px;
      display: flex;
      flex-direction: row;
      color: styles.$white;
      justify-content: space-between;
      align-items: center;

      & .modal-header-items {
        display: flex;
        flex-direction: row;
        font-size: 16px;
      }
    }

    & .modal-body {
      padding: 30px 20px;
      flex: 1;

      & .filter-select {
        box-shadow: 0 0 10px -5px styles.$black;
      }

      & .filters-filter-label {
        margin-bottom: 10px;
      }
    }

    & .modal-button-container {
      padding: 10px 20px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & .button {
        width: auto;
      }
    }
  }
}
