@use "constants/styles.scss";

.avatar {
  text-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.impersonated-avatar {

    & .generated-avatar,
    & .avatar-image,
    & .avatar-icon {
      opacity: 0.7;
    }
  }

  &.impersonate-avatar {
    position: absolute;
    bottom: 0;
    left: calc(50% - 70px);

    & .generated-avatar,
    & .avatar-image,
    & .avatar-icon {
      width: 50px !important;
      height: 50px !important;
      border: 2px solid styles.$white !important;
      font-size: 20px !important;
    }
  }

  &.compact-small-avatar {
    margin: 0 5px;
  }

  & .generated-avatar,
  & .avatar-icon,
  & .avatar-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid styles.$white;

    &.small {
      width: 35px !important;
      height: 35px !important;
      border: 1px solid styles.$white;
    }

    &.smallest {
      width: 20px !important;
      height: 20px !important;
      border: 1px solid styles.$white !important;
    }

    &.large {
      width: 140px !important;
      height: 140px !important;
    }

    &.archived {
      opacity: 0.4;
    }
  }

  & .avatar-wrapper-image {
    position: relative;
    cursor: pointer;

    & .avatar-image {
      border-radius: 50%;
      border: 3px solid styles.$white;

      &.small {
        min-width: 35px;
        border: 1px solid styles.$white;
      }
    }

    & .avatar-icon {
      width: 100px;
      height: 100px;
      overflow: hidden;
      background-color: styles.$lightest-grey;
      padding: 5px;
      color: styles.$dark-grey;
      border-radius: 50%;
    }

    & .avatar-tooltip {
      background-color: styles.$white;
      border-radius: 50%;
      position: absolute;
      bottom: 5px;
      right: 5px;
      width: 20px;
      height: 20px;

      &.small {
        bottom: -5px;
        right: -5px;

        & svg {
          margin-top: 1px;
          margin-left: 1px;
        }
      }
    }

    & .generated-avatar {
      border-radius: 50%;
      background-color: styles.$student-blue;
      color: styles.$white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 45px;
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      letter-spacing: 3px;

      &.small {
        font-size: 15px;
        letter-spacing: 1px;
      }

      &.smallest {
        font-size: 10px;
        letter-spacing: 0;
        border: 1px solid styles.$white;
      }

      &.large {
        font-size: 70px;
      }

      &.inactive {
        background-color: styles.$white;
        color: styles.$grey;
        border: 2px solid styles.$grey;
      }
    }

    & .avatar-button-edit {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      bottom: 10px;
      width: 24px;
      height: 24px;
      padding: 2px;
      background-color: styles.$white;
      border-radius: 50%;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);

      & .avatar-button-edit-icon {
        color: styles.$student-blue;
        width: 14px;
        height: 14px;
      }
    }
  }
}
