@use 'constants/styles.scss';

.form-input {
  display: flex;
  flex-direction: row;
  box-shadow: styles.$input-box-shadow;
  border: 1px solid styles.$light-grey;
  border-radius: 3px;
  max-height: 38px;
  background: styles.$white;

  & input {
    background: none;
    padding: 10px 20px;
    color: styles.$black;
    margin: 0;
    border: none;
  }

  &.custom-form-input {
    width: calc(130px - 48px);
  }

  &.number-input {
    width: 130px;

    & .custom-form-input-icon {
      width: 48px !important;
    }
  }

  & #custom-form-input {
    margin: 0;
    padding: 8px 15px;
    border: none !important;
    box-shadow: none;
  }

  & .custom-form-input-icon {
    background: styles.$light-grey;
    width: 46px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    & .date-picker-icon {
      color: styles.$grey;
      font-size: 14px;
    }
  }
}
