.todo-list-content {
  display: flex;
  justify-content: center;
  flex-direction: row;
  overflow: auto;
  min-height: calc(100vh - 200px);

  @media (max-width: 1168px) {
    flex-direction: column;
  }
}
