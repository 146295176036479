.no-elements-container {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  margin-top: 20px;
}

.workbook-scoring-information {
  width: 50%;
  text-align: center;
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 600;
}
