@use "constants/styles";
@use "constants/mixins";

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  padding-bottom: 15px;
  height:51px;
  @include mixins.phones() {
    padding-bottom: 0;
    padding-right: 10px;

    .header-title {
      font-size: 16px;
    }
  }
}

.header-info {
  display: flex;
  align-items: center;

  @include mixins.mobile {
    padding-left: 10px;
    align-items: flex-start;
  }
}

.header-back-btn {
  height: 22px;
  width: 22px;
  margin-right: 15px;
  color: styles.$white;
  cursor: pointer;
}

.test {
  display: flex;
  align-items: center;
}

.header-icon {
  height: 18px;
  width: 18px;
  color: styles.$white;
}

.header-title {
  margin: 0 0 0 10px;
  font-weight: bold;
  font-size: 18px;
  flex: 1;
}

.header-tab-wrapper {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: -1px;
  &::-webkit-scrollbar {
    display: none;
  }

  & .header-tab {
    gap: 5px;
  }
}

@include mixins.phones() {
  .test {
    display: none;
  }

  .header-tab-wrapper {
    padding: 10px 0 0;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
