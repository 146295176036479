@mixin phones() {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin tablets() {
  @media (min-width: 576px) and (max-width: 1024px) {
    @content;
  }
}

@mixin mobile() {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin desktop() {
  @media (min-width: 1025px) {
    @content;
  }
}

@mixin custom-breakpoint($width: 1024) {
  @media (max-width: (#{$width})+px) {
    @content;
  }
}

@mixin fade-in-animation($time: 0.4) {
  -webkit-animation: fade-in-from-none (#{$time})+s ease-in-out;
  -moz-animation: fade-in-from-none (#{$time})+s ease-in-out;
  -o-animation: fade-in-from-none (#{$time})+s ease-in-out;
  animation: fade-in-from-none (#{$time})+s ease-in-out;
}

@mixin opacity-frames() {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-in-from-none {
  @include opacity-frames;
}

@-moz-keyframes fade-in-from-none {
  @include opacity-frames;
}

@-o-keyframes fade-in-from-none {
  @include opacity-frames;
}

@keyframes fade-in-from-none {
  @include opacity-frames;
}

@mixin slide-from-left-animation($time: 0.4) {
  -webkit-animation: slide-from-left (#{$time})+s ease-in-out;
  -moz-animation: slide-from-left (#{$time})+s ease-in-out;
  -o-animation: slide-from-left (#{$time})+s ease-in-out;
  animation: slide-from-left (#{$time})+s ease-in-out;
}

@mixin slide-from-left-frames() {
  0% {
    left: -100%;
  }

  5% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}

@-webkit-keyframes slide-from-left {
  @include slide-from-left-frames;
}

@-moz-keyframes slide-from-left {
  @include slide-from-left-frames;
}

@-o-keyframes slide-from-left {
  @include slide-from-left-frames;
}

@keyframes slide-from-left {
  @include slide-from-left-frames;
}

@mixin slide-from-right-animation($time: 0.4) {
  -webkit-animation: slide-from-right (#{$time})+s ease-in-out;
  -moz-animation: slide-from-right (#{$time})+s ease-in-out;
  -o-animation: slide-from-right (#{$time})+s ease-in-out;
  animation: slide-from-right (#{$time})+s ease-in-out;
}

@mixin slide-from-right-frames() {
  0% {
    right: -100%;
  }

  5% {
    right: -100%;
  }

  100% {
    right: 0;
  }
}

@-webkit-keyframes slide-from-right {
  @include slide-from-right-frames;
}

@-moz-keyframes slide-from-right {
  @include slide-from-right-frames;
}

@-o-keyframes slide-from-right {
  @include slide-from-right-frames;
}

@keyframes slide-from-right {
  @include slide-from-right-frames;
}
