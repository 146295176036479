@use 'constants/mixins.scss';
@use 'constants/styles.scss';

.content-section {
  max-height: calc(100vh - 200px);
  box-sizing: border-box;

    background-color: styles.$lightest-grey;
    min-height: 100vh;

    &:not(.with-header) > div {
      height: calc(100vh - 200px);
    }

  & .content-section-container {
    max-width: 1225px;
    margin: auto;

    .section-list {
      height: calc(100vh - 150px);
      overflow: auto;
    }
  }

  &.with-header {
    padding-top: 130px;

    @include mixins.phones () {
      padding-right: 5px !important;
      padding-left: 0;
    }


    & .content-section-container {
      .section-list {
        height: calc(100vh - 250px);
        overflow: auto;

        @include mixins.phones () {
          height: calc(100vh - 180px);
        }
      }
    }
  }


}

.section-list {
  @include mixins.mobile() {
    width: 100%;
  }
}


.list-wrapper-actions-header {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 5x ;
}