@import "constants/styles.scss";

.loader {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  position: relative;

  & .loader-rings-container {
    width: max-content;
    position: relative;
  }

  & .loader-rings {
    transform: rotate(180deg);
    width: 100%;
    height: 100%;
    stroke-width: 7;
    fill: transparent;
  }

  & .loader-ring {
    transition: 0.8s stroke-dashoffset;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  & .loader-text-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    gap: 5px;

    div {
      margin: 2px;
    }
  }

  & .delta {
    color: #bbb;
    font-size: 18px;
  }

  & .loader-text {
    font-size: 18px;
    text-align: center;
    font-weight: 300;
    white-space: break-spaces;

    & .additional {
      color: #9f9f9f;
    }
  }

  & .loader-score {
    font-size: 42px;
    font-weight: 300;
    line-height: 1;
    width: 100%;
    text-align: center;

    @media (max-width: $design-breakpoint) {
      font-size: 42px;
    }

    @media (max-width: 900px) {
      font-size: 40px;
    }
  }
}
