@use "constants/styles.scss";
@use "constants/mixins.scss";
@use "constants/zIndexes.scss";

.sidenav {
  width: 230px;
  position: fixed;
  top: 0;
  left: 0;
  -ms-transform: none !important;
  transform: none !important;
  transition: all ease-in-out 0.2s;
  z-index: zIndexes.$sidenavIndex;

  &-overlay {
    display: none !important;
  }


  .sidenav-container {
    padding: 40px 0 !important;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mixins.phones() {
      padding: 0 0 40px!important;


      & .course-selection {
        margin-bottom: 20px;
      }
    }
  }

  .sidenav-instructors-avatar {
    position: absolute;
    height: 48px;
    width: 48px;
    top: 100px;
    left: 50px;
    z-index: 2;
    border: 3px solid #fff;
    border-radius: 50%;
  }

  & .log-block {
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: 26px;
  }

  & .menu-items {

    li {
      float: none;
      line-height: 26px;
      background: rgba(256, 256, 256, 0.15);
    }
    a {
          display: block;
          font-size: 15px;
          font-weight: 500;
          line-height: 48px;
        }
  }

  & .sidenav-holder {
    position: relative;
    min-height: 100vh;

    @include mixins.mobile() {
    width: 100%
    }
  }

  &.full {
    left: 0;
  }

  .sidenav-user {
    font-size: 15px;
    margin-top: 10px;
    mix-blend-mode: revert;
    text-align: center;
  }

  .sidenav-role {
    margin-top: 5px;
    font-size: 14px;
    opacity: 0.5;
    text-align: center;
  }

  .sidenav-logout-container {
    margin-top: 30px;
    font-size: 14px;
    text-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    opacity: 0.7;
    cursor: pointer;

    & .icon {
      margin-right: 10px;
      font-size: 16px;
      opacity: 0.7;
    }
  }

  .sidenav-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.mobile-menu {
  display: none;
}

.transition {
  left: 0;
}

@media (max-width: styles.$breakpoint-tablet) {
  .sidenav {
    background: none;
    position: fixed;
    z-index: 10000;
    width: 300px;
    padding-right: 30px;
    height: 100%;

    &.closed {
      left: -258px;
    }

    &.opened {
      width: 270px;
      padding-right: 0;
      left: 0;
      z-index: 10000;
    }
  }

  .mobile-menu {
    display: block;
    position: fixed;
    top: 5px;
    left: 5px;
    z-index: 100001;
    cursor: pointer;
    transition: left ease-in-out 0.2s;

    & .menu-icon {
      font-size: 36px;
    }
  }

  .transition {
    left: 240px;
    transition: all ease-in-out 0.2s;
  }
}

@include mixins.phones() {
  .sidenav {
    background: none;
    position: fixed;
    z-index: 10000;
    width: 300px;
    padding-right: 30px;
    height: 100%;
    transition: all ease-in-out 0.3s;

    &.closed {
      left: -500px;
    }

    &.opened {
      width: 100%;
      padding-right: 0;
      left: 0;
      z-index: 10000;
    }

    .sidenav-instructors-avatar {
      left: 120px;
    }
  }

  .mobile-menu {
    position: fixed;
    top: 10px;
    right: 15px;
    left: auto;

    & .menu-icon {
      font-size: 28px;
    }

    .transition {
      left: auto;
    }
  }
}

.avatars-container {
  position: relative;
}


.menu-item {
  display: flex;
  align-items: center;
  margin: 2px 13px;
  padding: 12px 10px 12px 15px;
  background-color: rgba(styles.$white, 0.15);
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;

  .menu-icon {
    color: styles.$white;
    width: 16px;
    height: 16px;
  }

  .menu-label {
    display: flex;
    justify-content: flex-start;
    font-size: 15px;
    color: styles.$white;
    margin: 0 0 0 10px;
  }
}

@include mixins.phones() {
  .menu-item {
    display: flex;
    align-items: center;
    width: 70%;

    .menu-icon {
      width: 13px;
      height: 13px;
    }
  }

  .menu-item--additional {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.dark {
  & .menu-item {
    background-color: rgba(styles.$black, 0.15);

    & .menu-icon, & .menu-label {
      color: styles.$black;
    }
  }
}
