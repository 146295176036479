@use "constants/styles.scss";

.form-select {
  margin: 15px 0;
  position: relative;
  width: 105px;

  & .select-label {
    margin: 5px 0;
    color: styles.$black;
    position: relative;
  }
}
