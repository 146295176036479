@use "constants/mixins.scss";

.search-bar {
  max-width: 180px;
  // padding: 5px 10px;
  background: white;
  border: 2px solid #dadada;
  color: #dadada;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mixins.phones() {
  max-width: 100%;
    width: 100%;
}

  &:hover {
    border-color: #999;
  }

  &--with-margin-left {
    margin-left: auto;
  }

  .search-bar-icon {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    background-color: #f2f2f2;
    width: 45px;
    font-size: 20px;
  }

  &-input {
    border: none !important;
    box-shadow: none !important;
    padding: 10px 10px;
  }
}
