@use 'constants/styles.scss';
@use 'constants/mixins.scss';

$mainColor : styles.$student-blue;

#answer-details-modal {
  width: 100%;
  position: relative;

  & .video-loader {
    position: absolute;
    bottom: 30%;
  }

  & .video-player {
    width: 100%;
    height: 100%;

    &.fullscreen {
      top: 50%;
    }
  }

  & a {
    color: $mainColor;
  }

  & * {
    outline: none;
  }

  & #video-control {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 30px;
    align-items: center;

    & #time-start-label,
    & #time-end-label {
      width: 41px;
    }

    & #time-progress {
      flex: 1;
      margin: 0 10px;

      & > div:first-child {
        background-color: $mainColor;
      }

      & > div:nth-child(2) > div {
        background-color: $mainColor;
      }
    }
  }

  & #video-control i {
    color: white;
  }

  & #video-control label {
    color: $mainColor;
  }

  & #video-control button {
    border: 1px solid white;
    width: 30px;
    height: 30px;
    justify-content: center;
    background: $mainColor;
    cursor: pointer;

    & .material-icons {
      position: relative;
      left: -4px;
    }
  }

  #seek-bar {
    padding-left: 20px;
    padding-right: 10px;
    text-align: center;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    flex: 1;

    @include mixins.phones() {
      max-width: 70vw;
    }

    & label {
      text-align: center;
    }
  }

  .input-range {
    width: 100px !important;
  }

  #volume-button,
  #full-screen-button {
    float: right;
  }

  #volume-bar-container {
    position: absolute;
    padding: 0 5px;
    transform: rotate(-90deg);
    right: -35px;
    bottom: 80px;
  }

  #volume-bar {
    width: 90px;
    background: none;

    input[type=range]::-webkit-slider-thumb {
      border: 1px solid styles.$student-blue;;
      background: transparent;
    }

    input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 8.4px;
      cursor: pointer;
      background: none;
      border-radius: 1.3px;
      border: 1px solid styles.$student-blue;;
    }
  }

  & #video-control * {
    & #video-control * {
      display: inline-flex;
    }
  }

  &.fullscreen-video {
    & #video-control {
      position: absolute;
      bottom: 0;
      padding: 10px 20px 30px;
    }

    & .panel-block {
      width: 100%;
      height: 100%;
      padding: 2% 5%;
      display: flex;
      justify-content: center;
      align-items: center;

      & > div {
        width: 100%;
        height: 100%;
      }
    }
  }

  & #video-player {
    position: relative;
    width: 100%;
    max-height: 600px;

    & video {
      position: relative;
      width: 100%;
    }
  }
}
