@use 'constants/styles.scss';

  .problem-row-answer {
    width: 45px;
    height: 45px;
    margin: 0 5px;
    cursor: pointer;
    box-shadow: inset 3px 3px 5px 0 rgba(50, 50, 50, 0.1), 3px 3px 3px 0 rgba(0, 0, 0, 0.15);
    font-weight: bold;
    font-size: 16px;
    background: styles.$white;
    padding: 20px;
    color: styles.$black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--selected {
      background: styles.$black;
      background: linear-gradient(-45deg, black 0%, #AAA 80%);
      color: styles.$white;
      box-shadow: inset 4px 3px 5px -1px rgba(50, 50, 50, 0.45), 3px 3px 3px 0 rgba(0, 0, 0, 0.15);
    }

    @media (max-width: styles.$design-breakpoint) {
      width: 40px;
      height: 40px;
    }
  }
