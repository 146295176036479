@use 'constants/styles.scss';
@use 'constants/mixins.scss';
$border: 2px solid #dadada;

.stats-container {
  width: 30%;
  margin-left: 20px;
  margin-top: 20px;

  @include mixins.mobile() {
    width: 100%;
    margin-left: 0;
    padding: 0 0 15px 0;
    align-self: center;
    justify-self: center;
  }
}

.stats {
  border: $border;
  background: white;
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include mixins.mobile() {

    justify-content: space-around;

    button {
      display: none;
    }
  }

  .stats-additional-button {
    display: none;
    margin-top: 30px;

    @media (max-width: styles.$design-breakpoint) {
      display: flex;
      justify-content: center;

      button {
        display: block;
      }
    }
  }

  .stats-header {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
    margin-left: 30px;
  }

  .stats-skills {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 15px;
  }
}
