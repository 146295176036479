@use "constants/styles.scss";
@use "constants/mixins.scss";

.lesson-wrapper {
  & .lesson-wrapper-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin: 10px 0 0;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    background-color: white;
    color: styles.$grey;
    border: 1px solid styles.$grey;
    border-left: 40px solid styles.$grey;

    @include mixins.phones() {
      border-left-width: 20px;
    }

    &.active {
      color: black;
      cursor: pointer;
      border-color: styles.$instructor-green;

      &.due-soon {
        border-color: styles.$orange;
      }

      &.over-due {
        border-color: styles.$red;
      }
    }

    & .lesson-title {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    & .lesson-details {
      display: flex;
      flex-direction: row;
      font-size: 12px;
      margin-top: 5px;
      font-weight: 500;

      & > div {
        margin-right: 40px;
      }
    }

    & .header-controller {
      display: flex;
      align-items: center;
    }

    & .lesson-arrow {
      font-size: 22px;
    }
  }

  & .lesson-wrapper-items-container {
    padding: 8px 20px 5px 60px;

    @include mixins.phones() {
      padding: 8px 10px 5px 10px;
    }
  }

  & .status-bubbles-container {
    display: flex;
    flex-direction: row;
    gap: 5px;

    & .status-bubble {
      color: styles.$white;
      padding: 1px 5px;
      border-radius: 20px;
      font-size: 12px;
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.overdue {
        background: styles.$red;
      }

      &.today {
        background: styles.$orange;
      }

      &.other {
        background: styles.$instructor-green;
      }
    }
  }

  .header-title {
    position: relative;
  }

  .no-lessons-unit-wrapper-info {
    position: absolute;
    right: 20px;
    font-size: 14px;

    .no-lessons-unit-wrapper-info-icon {
      font-size: 16px;
      margin-right: 10px;
      margin-left: 20px;
    }
  }
}
