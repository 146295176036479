$breakpoint-tablet: 1024px;
$design-breakpoint: 1140px;
$breakpoint-smartphone: 576px;
// Colors
$black: #000;
$white: #fff;
$dark-white:#f2f2f2;

$lightestBlue: #F4F6FC;
$light-blue: #5ABDFF;
$blue: #04A4E6;
$student-blue: #405A9C;
$selection-blue: #F5F6FD;

$instructor-green: #629E3D;
$lightest-green: #9FD18FCC;
$light-green: #62B144;
$green: #6AB23D;
$dark-green: #487F28;
$hover-green: #62A737;
$test-green: #318E12;
$test-green-hover: #39B44A;

$light-red: #F6483A;
$red: #E83B2D;
$dark-red: #B2281B;
$focus-red: #C74133;


$lightest-grey: #EDEDED;
$light-grey: #DADADA;
$grey: #999;
$mid-grey: #B8B8B8;
$dark-grey: #636466;

$orange: #E99200;
$yellow: #E2C400;
$lemon: #C3CE18;
$violet: #ab48af;
// Other variables
$input-box-shadow: 0 3px 6px $light-grey;
$menu-box-shadow: 0 0 4px 0 $grey;
$border-radius: 5px;
