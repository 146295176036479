@use "constants/mixins";

.webviewer-container {
  width: 100%;
  flex: 1;

  .webviewer {
    height: 75vh;
    width: 100% !important;
    flex: 1;

    @include mixins.phones() {
      width: 100vw;
    }
  }
}

