@use "constants/styles.scss";
@use "constants/mixins.scss";

.no-items-page {
  color: styles.$mid-grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
  width: calc(100% - 40px);

  & .no-items-page-icon {
    margin-top: 20px;
    font-size: 70px;
  }

  @include mixins.mobile() {
    height: calc(100vh - 100px);
  }
}
