@use "constants/styles.scss";
@use "constants/mixins.scss";

$mainColor: styles.$student-blue;

.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20000;
}

.red-text {
  color: styles.$red;
}

#review-modal {
  z-index: 20000;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1200px;
  background-color: styles.$white;
  border-radius: 5px;

  @include mixins.phones() {
    width: 100vw;
  }

  i {
    color: styles.$white;
  }

  & .modal-content {
    padding: 25px;

    @include mixins.phones() {
      padding: 5px;
    }
  }

  & .button-container {
    display: flex;
    flex-direction: row;
    margin: 25px;
    justify-content: space-between;

    @include mixins.phones() {
      flex-direction: column;
      gap: 10px;
      margin-top: 0;
    }
  }

  & .review-status-container {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    padding: 5px;
    width: 100%;

    & > span:first-child {
      width: 200px;
    }

    &#flag-container {
      cursor: pointer;
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;
      padding-top: 20px;
    }

    &#video-details {
      padding-bottom: 30px;
      padding-top: 10px;
      border-bottom: 1px solid #bbb;
    }
  }

  & .modal-close {
    font-size: 24px;
    margin: 0;
    color: styles.$white;
  }

  & .modal-footer {
    background-color: styles.$white;
  }

  & .modal-header {
    justify-content: space-between;
    padding: 0 15px;
    min-height: 50px;
    display: flex;
    background-color: $mainColor;
    color: styles.$white;
    font-size: 18px;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
