@use "constants/styles.scss";
.full-view{
  width: 100% !important;
  height: 100% !important;
  overflow: auto;
  .custom-modal-content {
    width: 100%;
    height: 100%;
  }
  .custom-modal-content-container{
    padding: 0 !important;
  }
}
.custom-modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100000;

  & .custom-modal {
    width: 400px;
    background: styles.$white;
    border-radius: 5px;
    box-shadow: 0 0 15px -10px styles.$black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .custom-modal-topbar {
      color: styles.$white;
      background: styles.$focus-red;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .custom-modal-topbar-header {
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
    }

    .custom-modal-content-container {
      height: calc(100% - 54px);
      padding: 30px;
      text-align: center;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
    }

    .custom-modal-content {
      width: 100%;
      border-bottom: 1px solid #dadada;
      padding-bottom: 25px;
      margin-bottom: 25px;
    }

    .custom-modal-header {
      color: styles.$focus-red;
      font-weight: 600;
      font-size: 17px;
      margin-bottom: 15px;
    }

    .custom-modal-message {
      font-size: 15px;
    }
  }
}

