@use 'constants/styles.scss';

.full-container {
  width: 100vw;
}

.error-modal-overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;

  .modal-container {
    width: 400px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
  }

  & .modal-content {
    background: styles.$white;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 15px 20px 0;

    & .modal-content-title {
      color: styles.$red;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    & .modal-content-info {
      width: 100%;
      padding-bottom: 30px;
      border-bottom: 1px solid styles.$light-grey;
      text-align: center;
    }
  }

  & .modal-header, & .modal-content, & .error-modal-buttons-container {
    width: 100%;
  }

  & .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: styles.$white;


    & .header-info {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .error-big-icon {
    font-size: 80px;
    margin-bottom: 20px;
    margin-top: 40px;
    color: styles.$red;
  }

  .error-modal-buttons-container {
    display: flex;
    justify-content: flex-end;
    background: styles.$white;
    padding: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    & .button {
      border-color: styles.$mid-grey;
    }
  }
}
