@use "constants/styles.scss";
@use "constants/zIndexes.scss";

.form-error {
  flex: 1;

  &.standard-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .error-tooltip {
    color: styles.$white;
    background-color: styles.$red;
    position: absolute;
    padding: 10px 25px 10px 10px;
    max-width: 200px;
    border-radius: 3px;
    z-index: zIndexes.$whatNewModal;

    & .error-tooltip-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }

    &::before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 12px 6px;
      border-color: transparent transparent styles.$red transparent;
      content: "";
      position: absolute;
      top: -10px;
      left: 10%;
      transform: translate(-50%, 0);
    }
  }
}
