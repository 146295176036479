@use "constants/styles.scss";

.profile {
    padding: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;

    @media (max-width: 1210px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

  & .profile-card-header {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 600;
  }

  & .profile-card-container {
    width: 100%;
  }

  & .profile-card {
    width: 100%;
    height: 100%;
    min-height: 430px;
    border: 2px solid styles.$lightest-grey;
    background: styles.$white;
    padding: 20px;

    @media (max-width: 1210px) {
      min-height: auto;
      padding: 30px;
    }
  }

  & .profile-info {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    align-items: center;
    flex-direction: column;

    & .profile-actions {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & .profile-names {
      width: 100%;

      & h3 {
        padding: 10px;
        text-align: left;
      }
    }

  }

  & .profile-avatar {
    width: 158px;
    height: 158px;
    object-fit: cover;
    border-radius: 50%;
  }

  & .profile-inputs {
    padding-top: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  & .profile-actions {
    width: 100%;
  }

  & .profile-state-zip {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 5px;
    margin-top: 10px;
    flex-direction: column;

    .input__container:nth-child(1) {
      width: 150%;
      padding-top: 15px;
      margin-right: 10px;
    }
  }

  & .profile-upload-button-container {
    width: 100%;
    display: flex;
    margin: 30px 0 25px;
    flex-direction: column;

    #file {
      opacity: 0;
      position: absolute;
      left: -9999px;
    }

    button {
      width: 100%;
      background: styles.$grey;
      transition: none !important;
      color: styles.$white;;
      font-size: 14px;
      height: auto;
      padding: 10px 15px 10px 10px;
      margin: 0 0 10px;

      &:focus {
        background: styles.$grey !important;
      }

      i {
        margin-right: 5px;
      }
    }
  }
}

.profile-button-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin-top: 20px;
}

.profile-select {
  box-shadow: 0 5px 15px -12px styles.$black !important;
  margin-bottom: 10px;
  width: 150px;

  & > div {
    border: 2px solid styles.$lightest-grey  !important;
    font-size: 14px !important;

    & > div > span {
      display: none;
    }
  }
}

.student-info {
  width: 100%;
  color: $grey;
  margin-bottom: 20px;
}

.profile .profile-actions {
  & > div {
    margin-bottom: 10px;
  }
}
