@use 'constants/styles.scss';

$disabledBackground: #eaaea8;
$disabledSecondary: styles.$grey;

.button {
  margin: 0 3px;
  font-size: 15px;
  height: 45px;
  white-space: nowrap;
  font-weight: 600;
  border-radius: 5px;
  text-transform: capitalize;
  padding: 0 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: styles.$white;
  border: 2px solid styles.$mid-grey;
  color: styles.$black;

  &:hover {
    border-color: styles.$dark-grey;
  }

  &:focus {
    border-color: styles.$grey;
  }

  &.off {
    opacity: 0.5;
    cursor: default;
  }

  & span > div {
    font-size: 12px;
    font-weight: 300;
    margin-top: 2px;
  }

  &.fixed-width {
    width: 200px;
  }

  &.pulse {
    position: relative;
    box-shadow: 0 0 0 0 styles.$red;
    animation: pulse 1.75s infinite cubic-bezier(0.66, 0, 0, 1);
  }

  &.pulse:hover {
    animation: none;
  }

  &.with-icon {
    padding-left: 10px;
  }

  @keyframes pulse {
    to {
      box-shadow: 0 0 0 20px rgba(25, 205, 52, 0);
    }
  }

  &.primary-reverse {
    border: 1px styles.$red solid;
    color: styles.$red;
    background-color: styles.$white;

    &:focus {
      border: 1px styles.$red solid;
      color: styles.$red;
      background-color: styles.$white;
      opacity: 0.8;
    }

    &:hover {
      border: 1px #bd1b0f solid;
    }
  }

  &.complete {
    color: styles.$white;
    background-color: styles.$green;
    border: 0;

    &:focus {
      color: styles.$white;
      opacity: 0.8;
      background-color: styles.$green;
      border: 0;
    }

    &:hover {
      background: styles.$hover-green;
    }
  }

  &:disabled {
    background: styles.$white;
    cursor: default;
    color: $disabledSecondary;
    border: 1px solid $disabledSecondary;
    pointer-events: none;
  }

  &.primary {
    background: styles.$red;
    color: styles.$white;
    border: none;

    &:hover {
      background: styles.$focus-red !important;
      color: styles.$white !important;
    }

    &:focus {
      background: styles.$focus-red !important;
      border: 2px styles.$focus-red solid;
      box-shadow: inset 0 0 0 1px styles.$white;
      color: styles.$white !important;
    }

    &:disabled {
      background: $disabledBackground;
      cursor: default;
      color: styles.$white;
      border: none;
    }
  }

  &.blue {
    background-color: styles.$student-blue;;
    border: none;
    color: styles.$white;

    &:focus {
      background: darken(styles.$student-blue, 5%) !important;
      opacity: 0.8;
      border: 2px darken(styles.$student-blue, 5%) solid;
      box-shadow: inset 0 0 0 1px styles.$white;
    }

    &:hover {
      background: darken(styles.$student-blue, 5%);
    }
  }

  &.grey {
    background-color: styles.$grey;
    color: styles.$white;
    border: none;

    &:focus {
      background: styles.$grey !important;
    }
  }

  &-icon {
    font-size: 20px;
    margin-right: 10px;
    font-weight: bold !important;
  }

  &.centered-button {
    position: fixed;
    bottom: 1.5em;
    z-index: 1000;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
}

.button-subtitle {
  font-size: 12px;
  font-weight: 300;
}
