@use "constants/mixins.scss";
@use "constants/styles.scss";

.navbar-container {
  width: 100%;
  position: fixed;
  z-index: 99;
  padding-right: 30px;

  @include mixins.phones() {
    width: 100vw;
    padding: 0;
  }

  #header {
    min-height: 0 !important;

    @media (min-width: 768px) {
      min-height: 0 !important;
      height: 60px;
    }
  }

  .navbar-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    color: styles.$black;


    .heading-block {
      margin-left: 10px;
      font-size: 23px;
      font-weight: 600;
    }

    .navbar_course-selector {
      display: flex;

    }
  }
}

.navbar-button-container  {
  background: styles.$grey;
  padding: 7px 15px !important;
  margin: 0 2px -4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: none;
  text-shadow: none;
  cursor: pointer;
  font-size: 15px;
  position: relative;
  top:1px;

  &:hover {
    background: styles.$white;

    a {
      color: styles.$black !important;
    }
  }

  &--active {
    border: 2px solid #dadada;
    border-bottom: none;
    background: styles.$white;
    padding-top: 8px;
    font-size: 16px;
    font-weight: bold;
    a {
      color: styles.$black !important;
    }
  }

  &--inActive {
    background: styles.$light-grey;
    pointer-events: none;
    a {
      color: styles.$grey;
    }
  }
}

.navbar-button {
  color: styles.$white !important;
  transition: none !important;

  &:hover {
    &::after {
      display: none;
    }
  }
}

.navbar-link {
    background: styles.$grey;
    font-weight: 600;
    padding: 10px 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border: 1px solid styles.$light-grey;
    border-bottom: none;
    color: styles.$white;
    margin-right: 1px;
    cursor: pointer;
    position: relative;

    &--active {
      background: styles.$white;
      color: styles.$black;
    }
}

.navbar-completed-date {
  display: flex;
  flex-direction: column;
  color: styles.$white;
  font-size: 16px;
  margin-right: 20px;

  & > span:first-child {
    font-size: 14px;

    @include mixins.phones() {
      font-size: 12px;
    }
  }
  & > span:last-child {
    font-weight: 600;

    @include mixins.phones() {
      font-size: 14px;
    }

  }
}

@media (min-width: 768px) {
  .instructor-page .title-row,
  .title-row {
    min-height: 0;
    padding: 25px 210px 5px 25px;
  }
}

.heading-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  color: styles.$white;

  @media (max-width: 768px) {
    margin-left: 0;
  }
}

@include mixins.phones() {
  .navbar-button-container {
    padding: 5px 10px  !important;
  }

  .navbar-container {
    padding-right: 0;
  }
  .navbar-content{
    justify-content: center;
    & h2 > span > svg {
      justify-self: flex-start;
    }
    .navbar_course-selector {
      display: none;
    }
}
}

.header-assignment-details {
  display: flex;
  font-size: 18px;
  color: styles.$white;
  margin-right: 20px;

  @include mixins.phones() {
    font-size: 12px;
  }
}