@use 'constants/styles.scss';

.participation {
  margin-bottom: 15px;

  & .participation-stats-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    @media (max-width: 1100px) {
      display: flex;
      flex-direction: column;
    }
  }


  & .participation-header {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;

    @media (max-width: 1100px) {
      margin: 0;
    }
  }

  & .participation-stats {
    height: 250px;
    margin: 2px 2px 0;
    padding: 0 8px;
    border: 1px solid styles.$light-grey;
    color: styles.$student-blue;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    background: styles.$white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & .participation-icon {
      width: 36px;
      height: 36px;
    }
  

    i {
      font-size: 40px;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 10px;
    }

    @media (max-width: 1300px) {
      i {
        font-size: 35px;
      }
    }

    @media (max-width: 1100px) {
      height: max-content;
      padding: 10px 0;
      display: grid;
      grid-template-columns: 0.5fr 1fr 1fr;

      i {
        margin-bottom: 0;
        font-size: 28px;
      }

      p {
        margin-bottom: 0;
        font-size: 15px;
      }
    }

    & .participation-orange {
      color: styles.$red;
      font-size: 20px;
      font-weight: 700;
    }

    & .participation-annotation {
      font-size: 20px;
      margin-bottom: 1px !important;
      margin-left: 10px;
      font-weight: 700;
    }
  }

  & .participation-info {
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.login-time {
      flex-direction: column;

      & > div:first-child {
        font-size: 14px;
        font-weight: 300;
      }
    }

    @media (max-width: 1300px) {
      font-size: 20px;
    }

    @media (max-width: 1100px) {
      font-size: 17px;
    }
  }

  & .participation-time {
    margin-left: 2px;
    font-weight: bold;
  }
}
