@use 'constants/styles';
@use 'constants/mixins.scss';

.grid-table {
  overflow: auto !important;
}

.table-action-bar {
  align-items: center;

  & > div:first-child {
    flex: 1;
  }

  & .filters-section {
    display: flex;
    flex-direction: row;
  }
}

.table-stats-progress-bar {
  display: flex;
  flex-direction: column;

  & .number-representation {
    text-align: right;
  }
}

.table-container {
  flex-direction: column;

  & .table-action-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}

.table-row > div:hover, {
  background-color: #f5f5f5;
}

.best-in-subject-cell {
  background-color: $light-grey;
  padding: 10px 0;
  color: black;
  border-radius: 5px;
  max-width: 55px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.best-total-cell {
  background-color: $instructor-green;
  padding: 10px 0;
  color: white;
  border-radius: 5px;
  max-width: 55px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.font-12 {
  font-size: 12px;
}

.table-list-title-cell {
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 12px;
  align-items: center;

  & > div > div:last-child {
    color: $grey;
  }
}

@include mixins.phones() {
  .table-container {
    justify-content: left;

    .table-action-bar {
      flex-wrap: wrap-reverse;
      justify-content: left;

      & > div:first-child {
        margin-left: 3px;
      }

      & .filters-section {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        margin: 5px 0;
      }
    }
  }
}

.item-selection-table-list {
  background-color: $white;
  padding: 20px 15px;
  border: 1px solid $light-grey;
}
