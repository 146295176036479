@use "constants/styles.scss";


.course-work {
  width: 33%;
  @media (max-width: 1400px) {
      width: 100%;
    
  }

  & .course-work-content {
    height: calc(100% - 45px);
    flex-direction: column !important;
    margin-right: 10px;

    @media (max-width: 1400px) {
      &.card-main {
        width: 100%;
        max-width: 100%;
        margin: 0 0 20px;
      }
    }
  }

  & .course-work-icon {
    color: styles.$student-blue;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: fit-content;
    margin: auto;

    i {
      font-size: 50px;
      margin-bottom: 10px;
    }

    @media (max-width: 1000px) {
      font-size: 18px;

      i {
        font-size: 40px;
      }
    }
  }

  & .course-work-counter {
    width: 36px;
    height: 36px;
    border: 3px solid styles.$white;
    color: styles.$white;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: styles.$red;
    border-radius: 50%;
    position: absolute;
    top: -7px;
    right: -20px;

    @media (max-width: 1000px) {
      width: 25px;
      height: 25px;
    }
  }

  & .course-work-items {
    width: 100%;

    .status-bar-container {
      width: 100%;
    }
  }

  & .course-work-status-bar {
    margin-bottom: 20px;
  }

  & .course-work-item-container {
    padding: 5px 0;
    border-bottom: 2px solid styles.$lightest-grey;
    font-weight: 600;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  & .course-work-item-rect {
    width: 40px;
    border-radius: 2px;
    color: styles.$white;;
    padding-bottom: 1px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--completed {
      background: styles.$green;;
    }

    &--overdue {
      background: styles.$red;
    }

    &--today {
      background: styles.$orange;
    }

    &--soon {
      background: styles.$yellow;
    }
  }
}
