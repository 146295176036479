@use 'constants/styles.scss';

.info-icon-container {
  position: fixed;
  top: 0;
  right: 20px;

  & .info-circle {
    font-size: 20px;
    background-color: styles.$white !important;
    border-radius: 50%;
  }
}
