@use 'constants/styles.scss';

.tooltip-container {
  position: relative;

  .tooltip-bubble {
    display: none;

    span {
      margin: 0;
    }
  }

  &:hover {
    .tooltip-bubble {
      min-width: 120px;
      background: styles.$black;
      color: styles.$white;
      font-size: 12px;
      border-radius: 5px;
      padding: 5px 8px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      transform: translateY(10px);
      z-index: 9999999;
      display: block;

      &::before {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 6px 12px 6px;
        border-color: transparent transparent styles.$black transparent;
        content: "";
        position: fixed;
        top: -10px;
      }

      &.white {
        background: styles.$white;
        color: styles.$black;
        box-shadow: styles.$input-box-shadow;
        padding: 10px;
        text-align: left;

        &::before {
          transform: rotate(45deg);
          background-color: styles.$white;
          width: 12px;
          height: 12px;
          content: "";
          top: -7px;
          border-color: styles.$mid-grey transparent transparent styles.$mid-grey;
          border-width: 1px;
        }
      }

      &.left {
        right: -50%;

        &::before {
          right: 10px;
        }
      }

      &.whole-left {
        right: calc(100% + 10px);
        top: -50%;

        &::before {
          display: none;
        }

        &::after {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 6px 6px 12px;
          border-color: transparent transparent transparent styles.$black;
          content: "";
          position: fixed;
          right: -18px;
          top: 10px;
        }
      }

      &.right {
        left: calc(100% + 10px);
        top: -50%;

        &::before {
          display: none;
        }

        &::after {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 12px 6px 6px;
          border-color: transparent styles.$black transparent transparent;
          content: "";
          position: fixed;
          left: -18px;
          top: 8px;
        }
      }

      &.row {
        flex-direction: row;
      }
    }
  }
}
