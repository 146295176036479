@use 'constants/styles';
@use 'constants/mixins.scss';
@use 'constants/zIndexes';

.global-spinner-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  bottom: 0!important;
  right: 0!important;
  background-color: rgba(styles.$black, 0.4);
  align-items: center;
  justify-content: center;
  z-index: zIndexes.$globalSpinner;
  width: 100vw!important;
  max-width: 100vw!important;
  height: 100vh!important;

  & .global-spinner {
    color: styles.$white;
  }

  & .global-spinner-text {
    color: styles.$white !important;
    font-size: 28px !important;

    @include mixins.phones() {
      font-size: 20px !important;
    }

    & span {
      animation: animate-text 4s linear infinite;
    }
  }

  &.local {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(styles.$white, 0.7);

    & .global-spinner {
      color: styles.$black;
    }

    & .text.global-spinner-text {
      color: styles.$grey !important;
      font-size: 28px !important;
      animation: animate-text 4s linear infinite;
    }
  }
}

@keyframes animate-text {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.01;
  }
}
