@use "constants/mixins.scss";
@use "constants/styles.scss";

.test-navbar {
  .menu-additional {
    margin-left: -2px !important;
    margin-bottom: 4px;
  }

  nav a {
    color: inherit;
  }
}

.test-scores-container {

  .summary-loader {
      margin: 0 5px;
    }

  .info-icon-container {
    display: none;
  }
}

.test-answer-sheet-container {
  background: styles.$white;
  border: 2px solid styles.$light-grey;
  border-radius: 0 5px 5px 5px;

  @include mixins.phones() {
    max-width: 100vw;
  }
}

.test {

 .test-details {
    .graphs-block {
      margin: 10px 0;
      padding: 0 5px 5px 0;
    }
  }

  .test-no-sections-message {
    margin: 20px 0;
    text-align: center;
  }

  .test-section-header {
    cursor: pointer;
    border: solid 1px styles.$dark-white;
    padding: 15px;
    background-color: styles.$test-green-hover;
    color: styles.$white;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
  }

  .test-submit-button {
    border: solid 1px styles.$test-green;
    color: styles.$test-green;
    background-color: styles.$white;

    &:hover {
      background-color: styles.$white;
      color: styles.$test-green-hover;
    }
  }
}

.answers-main-slick {
  padding-bottom: 15px;
  background: styles.$dark-white;
}

.start-test-page {
  margin-bottom: 15px;

  .task-container {
    border-top: 2px solid styles.$light-grey;
    border-radius: 5px;
    height: 135px !important;
  }

  .skill-header {
    text-align: right;
  }
}

.test-sections-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 5px;
}

.tests-info-wrapper {
  width: 100%;
  padding: 15px 20px;
  border-bottom: 2px solid styles.$light-grey;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  position: sticky;
  top: 10px;
  background-color: styles.$white;

  .tests-counter-container {
    display: flex;

    @include mixins.phones() {
      width: 100%;
      justify-content: center;
    }

    .tests-counter {
      margin-left: 30px;
      width: 150px;
      height: 100%;
      background: styles.$dark-white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include mixins.phones() {
        width: 100%;
      }
    }

    .texts-counter-time {
      font-weight: bold;
      font-size: 17px;
      margin-top: 5px;
    }
  }

  .info-header {
    margin-bottom: 25px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    b {
      font-size: 18px;
      font-weight: bold;
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }

  @include mixins.phones() {
    top: 80px;
    flex-direction: column;
    width: 380px;
    position: fixed;

    & > div {
      width: 380px;
      align-items: center;
    }
  }
}

.tests-list {
  width: 100%;
  max-width: 1120px;
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-count: 3;
  column-width: 360px;
  box-sizing: border-box;
  align-items: center;
  padding-inline-start: 0;
  padding: 15px 0;
  margin: 10px 0;
  background: styles.$white;

  @include mixins.phones() {
    margin-top: 150px;
    padding: 0;
  }

  & > div {
    width: 360px;
    max-height: 74px;
    border-left: 10px solid styles.$white;
    display: inline-flex;
    break-inside: avoid;

    @include mixins.phones() {
      overflow: scroll;
      margin-left: 10px;
    }
  }
}


