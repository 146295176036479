@use "constants/styles.scss";

.clear-icon {
  position: absolute;
  color: styles.$black;
  right: 2px;
  cursor: pointer;
}

.video-button-icon {
  color: styles.$black;
  margin-left: 1px;
}
