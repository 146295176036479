@use "constants/styles.scss";

.book-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .book-icon {
    font-size: 22px;
  }

  .book-letter {
    position: absolute;
    color: styles.$white;
    font-weight: bold;
    bottom: 6px;
  }
}
