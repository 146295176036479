@use "constants/styles.scss";
@use "constants/mixins.scss";

$borderColor: #dadada;

.small-radius {
  min-width: 40px !important;
  border-radius: 4px !important;
  margin-right: 10px !important;
  font-size: 13px !important;
  line-height: 1.5 !important;
  padding: 2px 3px !important;
}

.group-block-container {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;

  @include mixins.mobile() {
    flex-direction: column;
  }

  .group-block-stats-container {
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      margin: 0 8px;
    }
  }
}

.group-block-dropdown-space {
  width: 25px;
  height: 23px;
}

.group-block{
  box-shadow: 0 0 10px -5px styles.$black;
  border-radius: 5px;
  margin: 8px;
  padding: 10px;

  .group-block-stats {
    min-width: 80px;
    text-align: center;

    @media (max-width: 992px) {
      min-width: 51px;
    }
  }

  .group-block-header-container {
    display: flex;
    justify-content: center;
    align-items: center;

    @include mixins.mobile() {
      justify-content: flex-start;
    }
  }

  .group-block-header {
    max-width: 300px;
    font-weight: bold;
    font-size: 18px;

    @media (max-width: styles.$design-breakpoint) {
      font-size: 17px;
    }

    @media (max-width: 900px) {
      font-size: 15px;
    }
  }

  .group-block-children {
    max-height: 0;
    overflow: hidden;
    padding-left: 10px;

    .group-block {
      .group-block-container {
        box-shadow: none;
        border-bottom: 1px dotted styles.$grey;
        border-radius: 0;
        margin: 5px 0 0;
        padding-bottom: 5px;

        &:last-of-type {
          border-bottom: none;
          padding-bottom: 0;
        }
      }

      .group-block-header {
        max-width: 300px;
        font-weight: 500;
        font-size: 15px;

        @media (max-width: styles.$design-breakpoint) {
          font-size: 14px;
        }
      }
    }

    &--expanded {
      max-height: 10000px;
    }
  }
}

.status-bar {
  flex: 1;
  .status-bar-container {
    width: 150px;
    height: 8px;
    background: #eaeaea;
    margin-left: auto;
    border-radius: 2px;

    .status-bar-progress {
      width: 50%;
      height: 100%;
      background: styles.$green;
      border-radius: 2px;
    }
  }
}

.orange {
  background-color: styles.$orange !important;
}

.light-green {
  background-color: styles.$green !important;
}

.red {
  background-color: styles.$red !important;
}

.white-text {
  color: styles.$white !important;
}
