.fullscreen-image-container {
  z-index: 100000000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(black, 0.8);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  & .modal-content {
    max-width: 98vw;
    max-height: 98vh;
    position: relative;

    & .fullscreen-image-close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 30px;
      margin: 10px;
      background: white;
      width: 30px;
      cursor: pointer;
    }

    & img {
      max-width: 98vw;
      max-height: 96vh;
    }
  }
}
