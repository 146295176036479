@use "constants/styles.scss";

.summary-header-container {
  margin-bottom: 20px;
  text-align: center;

  h2 {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  @media (max-width: 900px) {
    h2 {
      font-size: 17px;
    }
  }
}

.summary-annotation {
  font-weight: normal;
  font-size: 15px;
}

.summary-loader  {
  position: relative;
}

.summary-loaders {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 1270px) {
    //flex-direction: column;
    @media (max-width: 1400px) {
      display: flex;
    }

    @media (max-width: 1000px) {
      .summary-loader {
        margin: 20px;
      }
    }
  }

  .summary-loader {
    margin: 0 20px;
    position: relative;
  }
}

.summary-content {
  background: white;
  padding: 40px;
  margin-bottom: 20px;
  box-shadow: none;
  border: 2px solid styles.$light-grey;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  border-radius: 5px;
  width: 100%;

  @media (max-width: 1400px) {
    flex-direction: column;
  }

  // C heck if needs to be removed
  & .summary-dashboard {
    .summary-scores--multiple {
      margin-right: 5%;

      @media (max-width: 1400px) {
        margin-right: 0;
      }
    }
  }
}

.summary {
  display: flex;
  flex-direction: column;

  & .summary-row {
    display: flex;
    flex-direction: row;

    @media (max-width: 1400px) {
      flex-direction: column;
    }
  }

  & .summary-label {
    font-weight: 600 !important;
    font-size: 17px !important;
    margin-bottom: 10px;
  }

  .breaker-container {
    height: 100%;
    display: block;
    border: 2px solid styles.$lightest-grey;

    @media (max-width: 1400px) {
      display: none;
    }
  }
}
