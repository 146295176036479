@use 'constants/mixins.scss';
@use 'constants/styles.scss';

.scoring-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  @media (max-width: 1100px) {
    flex-direction: column;
  }

  @include mixins.phones() {
    max-width: 100vw;
  }

  .nav-additional {
    z-index: 1;
  }

  .menu-additional {
    margin-left: -2px !important;
    margin-bottom: 5px;
  }

  nav a {
    color: inherit;
  }

  .navbar-button-container {
      border: 2px solid styles.$lightest-grey;
      border-bottom: 0;
  }
}

.scoring-content-container {
  min-width: 350px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  height: 100%;


  @include mixins.mobile {
    width: 100%;
    margin-right: 0;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.scoring-content {
    padding: 40px 30px;
    background: styles.$white;
    border: 2px solid styles.$lightest-grey;
    border-radius: 0 5px 5px 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

.scoring-details {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

.scoring-detail-container {
  width: 100%;
  border-bottom: 2px solid styles.$lightest-grey;
  margin-bottom: 6px;
  padding-bottom: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

    & > div {
      width: 50%;
    }

    & > div:first-child {
      text-align: right;
    }

    &:last-of-type {
      margin-bottom: 0;
      border-bottom: none;
    }
  }

.scoring-detail-header {
    white-space: nowrap;
    text-align: right;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

  }

.scoring-detail {
    width: 80px;
    text-align: left;
    display: flex;
    align-items: center;

    & svg {
      margin-right: 10px;
    }

    &--rating {
      text-align: center;
      padding: 5px 0;
      border-radius: 5px;
      font-weight: 600;
      background: styles.$green;
      color: styles.$white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--red {
      color: styles.$red;
      font-weight: 600;
    }

    i {
      font-size: 15px;
      margin-right: 5px;
    }
  }

.scoring-button-container {
    margin-top: 15px;
  }

