@use 'constants/styles';

.course-selection {
  color: styles.$white;
  display: flex;
  justify-content: center;
  align-items: center;

  .header {
    font-weight: 500;
    font-size: 14px;
    margin: 0 25px 0 0;
    color: styles.$white;
    display: block;
  }

  & .course-type {
    background: styles.$dark-grey;
    color: styles.$white;
    border-radius: 3px;
    cursor: pointer;
    padding: 8px 15px;

    &.active {
      transform: scale(1.1);
      background: styles.$white;
      font-weight: 600;
      color: styles.$black;
      box-shadow: styles.$menu-box-shadow;
    }
  }
}

.course-selector-label {
  color: styles.$white;
}

@media (max-width: styles.$breakpoint-smartphone) {
  .course-selection {
    flex-direction: row;

    .header {
      display: block;
    }
  }
}
