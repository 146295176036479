.time-tracker-header {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 14px;
  margin-bottom: 5px;

  & .time-tracker-icon {
    font-size: 16px;

  }
}