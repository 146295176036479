@use 'constants/styles.scss';
@use 'constants/mixins.scss';

.problem-counter {
  margin: 2px;
  background: styles.$lightestBlue;
  width: 60px;
  color: styles.$black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  box-sizing: border-box;

  @include mixins.phones() {
    width: 30px;
  }
}

.problem-answers-container {
  display: flex;
  flex-direction: row;
    width: 350px;
    margin: 1px;

    @include mixins.phones() {
      margin-right: 0!important;
      max-width: 340px;
    }
  }

.problem-answers {
    margin: 2px;
    flex: 1;
    height: 70px;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    background: styles.$lightestBlue;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-sizing: border-box;

    .assignment-input-content {
      &:focus {
        border: 2px solid styles.$mid-grey !important;
      }
    }

    .assignment-input {
      width: 100% !important;
    }
  }

.answer-list {
  color: styles.$grey;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 350px;
  box-sizing: border-box;

  @include mixins.phones() {
    max-width: 350px;
    padding: 0 10px;
  }
}
