@use "constants/styles";
.skill {
    &.test-progress-bar {
      min-width: 150px;
      padding-left: 30px;
    }

    margin-bottom: 15px;

    & .status-bar-container {
      background-color: styles.$lightest-grey;
      border-radius: 5px;
      width: 100%;
      height: 8px;

      & .status-bar-progress {
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }

    &:nth-of-type(1) {
      .status-bar-progress {
        background: #60b244;
      }
    }

    &:nth-of-type(2) {
      .status-bar-progress {
        background: #41bdff;
      }
    }

    &:nth-of-type(3) {
      .status-bar-progress {
        background: #ee951d;
      }
    }
  }

  .skill-header {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .skill-numbers {
    margin-top: 10px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .skill-number {
    &--additional {
      color: #9f9f9f;
    }
  }

.assignment-status-bar .status-bar-progress {
  background: styles.$student-blue!important;
  width: 100%;
  height: 8px;
  border-radius: 5px;
}

.table-stats-progress-bar .status-bar-container {
  background-color: styles.$lightest-grey;
  border-radius: 5px;
  width: 100%;
  height: 8px;

  & .status-bar-progress {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
}
