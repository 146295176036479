@use 'constants/styles.scss';
.test-scores {
  width:67%;
  @media (max-width: 1400px) {
    width:100%
  }

  .menu-additional {
    margin-left: -2px !important;
    margin-bottom: 4px;
  }

  nav a {
    color: inherit;
  }

  & .test-scores-content {
    width: 100%;
    z-index: -999 !important;
    padding: 0 !important;
    border-top-left-radius: 0;

  }

  .navbar-button-container {
      border: 2px solid styles.$lightest-grey;
      border-bottom: 0;
  }
}
