.worksheet-content-section {
  height: calc(100vh);
  padding-top: 120px !important;
  overflow: auto;
}

.chart-block {
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0 0 20px;
}

.chart-block .chart-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  width: 64%;
}
