@use 'constants/styles.scss';

.context-menu-container {
  position: relative;
  cursor: pointer;

  & .context-menu-content {
    display: none;

    &.opened {
      display: flex;
      position: absolute;
      flex-direction: column;
      background-color: styles.$white;
      border-radius: 5px;
      transform: translate(-50%, 0%);
      padding: 5px 0;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

      & .context-menu-item {
        padding: 10px;
        text-align: center;
        color: styles.$black;
        width: 200px;

        &:hover {
          background: styles.$grey;
        }
      }
    }
  }
}
