@use 'constants/styles.scss';
@use 'constants/mixins.scss';

.test-item-wrapper{
  display: flex;
  flex-grow: 1;
}

.test-item-container {
  display: flex;
  margin: 10px;
  background-color: styles.$white;
  border: 1px solid styles.$mid-grey;
  border-radius: 5px;

  @include mixins.mobile() {
    margin: 5px 0 0;
  }

  .test-item-side-nav {
    display: flex;
    width: 60px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  .test-item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 15px 60px;
  }

  .test-item-title {
    padding: 10px 0 0;
    font-size: 18px;
    font-weight: bold;
    color: styles.$black;
  }

  .test-item-status {
    padding: 5px 0 10px;
    font-size: 12px;
    font-weight: bold;
    text-transform: initial;
    color: styles.$light-green;
  }

  .test-item-number,
  .test-item-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 13px;
  }

  .test-item-icon-wrapper {
    margin-right: 5px;
  }

  .test-item--icon {
    font-size: 15px;
  }

  .test-item-buttons {
    display: flex;
    justify-content: center;
    padding-top: 25px;
  }
}
