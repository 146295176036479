@use "constants/mixins.scss";
@use "constants/zIndexes.scss";
@use "constants/styles.scss";

.layout-wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 230px 1fr;
  grid-template-rows: 100px 1fr;
  grid-template-areas:
    "sidenav header "
    "sidenav main ";
  scrollbar-gutter: stable;
}

.layout-header {
  grid-area: header;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: zIndexes.$headerIndex;
  padding: 15px 30px 0;
  border-bottom: 2px solid styles.$lightest-grey;

  @include mixins.phones {
    padding: 5px 0 0;
  }

  @include mixins.tablets {
    padding: 15px 10px 0;
  }

  & > div {
    max-width: 1440px;
    width: 100%;
  }
}

.layout-main {
  grid-area: main;
  height: 100%;
  padding: 15px 30px;
  background-color: styles.$lightest-grey;
  overflow: hidden;
  margin: auto;
  width: 100%;

  & > div {
    max-width: 1440px;
    width: 100%;
  }

  @include mixins.phones() {
    padding: 10px 0 0 0 ;
    width: 100%;
  }
  @include mixins.tablets() {
    padding: 15px 20px;
    width: calc(100% - 40px);
  }
}

.layout-header, .layout-main {
  @include mixins.tablets() {
    margin-left: 40px;
  }
}

.layout-sidenav {
  grid-area: sidenav;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  height: 100vh;
  padding: 20px 0;
  background-color: styles.$grey;
}

@include mixins.mobile {
  .layout-wrapper {
    grid-template-areas:
      "header"
      "main ";
    grid-template-columns: 100%;
  }

  .layout-sidenav {
    display: none;

    @include mixins.slide-from-left-animation(0.1);

    &.closed {
      left: -250px;
    }
  }

  .layout-header {
    padding-right: 5px;
  }
}

.opened {
  display: flex;
  position: fixed;
  width: 250px;
  grid-area: sidenav;
  background-color: styles.$grey;
  height: 100vh;
  padding: 20px 0;
  z-index: zIndexes.$sidenavIndex;

  @include mixins.tablets {
    width: 250px;
  }
}

@include mixins.phones {
  .opened {
    width: 100%;
  }
}
