@use 'constants/styles.scss';
@use 'constants/mixins.scss';

#header {
  background: #f2f2f2;
  border-bottom: 2px solid#dadada;

  .courses-dropdown {
    text-shadow: none;
  }

  @media (max-width: styles.$breakpoint-tablet) {
    padding: 10px 14px 6px;
  }
}

@include mixins.phones(){
  #header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 14px 0;
  }
}