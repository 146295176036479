@use 'constants/styles.scss';
@use 'constants/mixins.scss';
$border: 2px solid #dadada;

.task-container {
  width: 100%;
  background: styles.$white;
  display: flex;
  margin-bottom: 8px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;

  .task-left-bar {
    width: 45px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: styles.$green;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mixins.phones() {
      width: 35px;
    }

    &.light {
      background-color: #a0d18f;
      opacity: 0.8;
    }

    &.overdue {
      background: styles.$red;
    }

    &.today {
      background: styles.$orange;
    }

    &.item-unavailable {
      background: styles.$grey;
    }
  }

  .task-content-container {
    width: calc(100% - 60px);
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    @include mixins.phones() {
      flex-wrap: wrap;
      padding-right: 0;

      & .task-content {
        padding-right: 0;
      }
      & .task-buttons {
        height: auto;
        margin-top: 20px;
        gap: 5px;


        & button {
          width: 100%;
          margin: auto;
        }
      }
    }
  }

  .task-content {
    padding-right: 10px;
  }

  .task-title-container {
    display: flex;
    align-items: center;
    cursor: pointer;

    .task-icon-wrapper {
      width: 30px !important;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: styles.$white;
      background-color: styles.$red;

      .task-icon {
        font-size: 18px;
      }
    }

    i {
      font-size: 18px;
    }

    & .task-title {
      margin-left: 10px;
      padding-top: 5px;
      font-size: 17px;
      font-weight: 700;
      display: flex;
      flex-direction: row;

      @media (max-width: 950px) {
        font-size: 16px;
      }

      @media (max-width: 800px) {
        font-size: 15px;
      }
    }
  }

  .task-version {
    margin-top: 5px;
  }

  .task-status-container {
    margin-top: 25px;
    display: flex;
    align-items: center;

    &--today {
      color: styles.$orange;
    }
  }

  .task-status-icon {
    margin-right: 7px;
    background: styles.$green;
  }

  .task-status {
    color: styles.$green;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
    margin-right: 30px;

    &.today {
      color: styles.$orange;
    }

    &.assigned {
      color: styles.$grey;
    }

    &.overdue {
      color: styles.$red;
    }
  }

  .task-date {
    margin-left: 8px;
  }

  .task-buttons {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px 0;
    height: 100%;

    @media (max-width: 950px) {
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: flex-end;

      button {
        font-size: 14px;
        height: 40px;
      }
    }
  }
}

.ico-mark {
  width: 11px;
  height: 11px;
  border-radius: 50%;
}

.badge-circle {
  width: 41px;
  height: 41px;
  vertical-align: top;
  background-color: #b2b2b2;
  background-clip: border-box;
  border: 1px solid #b2b2b2;
  border-radius: 50%;
  color: styles.$white;
  font-size: 14px;
  line-height: 1;
  margin: 0 0 4px;
  padding: 5px 5px 7px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  flex-wrap: wrap;
  position: relative;
}

.to-do-list-general {
  width: 100%;

  &:not(.browser-default) {
    padding: 5px 0 5px 31px;
    position: relative;
    width: 100%;

    & .badge-circle {
      width: 19px;
      height: 19px;
      color: styles.$white;
      border: none;
      font-size: 12px;
      font-weight: 700;
      line-height: 13px;
      padding: 2px;

      &--pill {
        width: max-content;
        padding: 0 5px;
        border-radius: 20px;
      }
    }
  }

  &::before {
    content: "";
    width: 3px;
    background: #cbcbcb;
    position: absolute;
    top: 5px;
    bottom: 0;
    left: 10px;
  }

  & .todo-list-row {
    position: relative;
    margin: 0 0 29px;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      width: 25px;
      height: 25px;
      content: "";
      background: styles.$white;
      border: 6px solid styles.$grey;
      border-radius: 50%;
      position: absolute;
      top: -3px;
      right: 100%;
      margin: 0 6px 0 0;
    }

    &.period_overdue {
      &::before {
        border-color: styles.$red;
      }

      & .do-title {
        color: styles.$red;
      }

      & .badge-circle {
        background-color: styles.$red;
      }
    }

    &.period_today {
      &::before {
        border-color: styles.$orange;
      }

      & .do-title {
        color: styles.$orange;
      }

      & .badge-circle {
        background-color: styles.$orange;
      }
    }

    &.period_tomorrow,
    &.period_next_week,
    &.period_after_next_week,
    &.period_this_week,
    &.period_no_due_date {
      &::before {
        border-color: styles.$green;
      }

      & .do-title {
        color: styles.$green;
      }

      & .badge-circle {
        background-color: styles.$green;
      }
    }
  }

  & .title-block {
    padding: 0 0 0 5px;
  }

  & .do-title {
    display: block;
    font-size: 18px;
    margin: 0 0 12px;
    font-weight: 600;

    & .badge-circle {
      margin: 1px 0 0 5px;
    }
  }

  & .badge {
    float: none;
  }
}

.to-do-list-general {
  @include mixins.mobile() {
    width: calc(100vw - 10px);
  }
}
