@use 'constants/styles.scss';

.video-button-icon {
  margin-left: 4px;
  margin-bottom: 0;
  font-size: 18px;
  color: styles.$black;

  &.no-video {
    color: styles.$grey;
    cursor: auto;
  }
}

.video-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
