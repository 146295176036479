@use "constants/styles.scss";

.modal-container#news-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;

  & .modal-content {
    padding: 20px;
    background-color: styles.$white;

    & .card {
      margin: 0;
    }
  }

  &.transparent-overflow .modal-content {
    padding: 0;
  }
}

.modal-container#news-modal {
  padding: 5%;

  & .modal-content {
    position: relative;
    border-radius: 10px;
    max-width: 90vw;
    padding: 0 0 20px;

    & .carousel-close-button {
      position: absolute;
      right: 20px;
      top: 7px;
      cursor: pointer;
      font-size: 24px;
      z-index: 1000;
    }
  }
}
