$zIndex1: 1;
$headerIndexes: 5;
$calendarHeaderIndexes: 10;
$mainModalIndexes: 15;
$childrenModalIndexes: 20;
$atePickerModalIndexes: 30;
$zIndex50: 50;
$zIndex100: 100;
$headerIndex: 999;
$modalHeader: 1000;
$contextMenuSubmenu: 1000;
$modalIndex: 1500;
$whatNewModal: 10000;
$detailsInList: 10030;
$contextMenu: 99996;
$toastIndex: 999998;
$sidenavBackgroundIndex: 99998;
$sidenavIndex: 9999;
$detailsModal: 100000;
$globalSpinner: 100001;
$tooltip-bubble: 10000000;
$tooltip-bubble-triangle: 10000001;
