@use "constants/styles";

.local-spinner {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 50px;
}

.full-element-spinner {
  width: 100%;
  max-height: 100%;
  display: flex;
  padding: 25% 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .description-text {
    margin-top: 20px;
    color: styles.$white;
  }
}
