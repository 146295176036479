@use 'constants/styles.scss';
@use 'constants/mixins.scss';

.weaknesses {
  padding: 10px 0;
  display: flex;
  justify-content: space-around;
  flex-direction: row;

  @media (max-width: styles.$design-breakpoint) {
    flex-direction: column;
  }

  .stats {
    padding-bottom: 0;
    height: 100%; //Check if this is needed
  }

  & .weaknesses-sub-container {
    width: 70%;

    @media (max-width: styles.$design-breakpoint) {
      width: 100%;
    }
  }

  & .weaknesses-content {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;

    @include mixins.phones() {
      width: 100%;
    }

    & .search-bar {
      margin-bottom: 0;
      max-width: 300px;
      width: 300px;

      @include mixins.phones() {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  & .weaknesses-card {
    height: calc(100vh - 280px);
    overflow: auto;
    padding: 15px;
    border-top-left-radius: 0;
    box-shadow: none;
    border: 1px solid styles.$lightest-grey;
    border-top: none;

    @include mixins.mobile() {
      height: 40vh;
      padding: 0;
    }
  }
}

.badge {
  text-align: center;
  font-weight: bold;
}

.strengths-and-weaknesses-navbar-with-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

@include mixins.phones() {
  .weaknesses-content {
    width: 260px;
    order:1;
  }

  .mb-0{
    order:2;
  }
}