@import "constants/mixins.scss";

.strengths-and-weaknesses-nav-bar {
  font-size: 15px;
  display: flex;
  margin: 0 auto;
}

@include phones(){
  .navbar-link{
    padding: 8px 5px;
  }

}
