@use "constants/styles.scss";

.carousel-container {
  position: relative;

  & .slide-title {
    font-size: 23px;
    padding: 20px 20px 20px 50px;
    margin-bottom: 20px;
    font-weight: 600;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  & .slides-left-arrow,
  .slides-right-arrow {
    height: 90%;
    position: absolute;
    top: 10%;
    background: rgba(styles.$white, 0.5);
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1000;
    padding: 10px;
    border-radius: 10px;
    font-size: 24px;

    &:hover {
      background: rgba(#eee, 0.5);
    }

    & i {
      font-size: 32px;
    }
  }

  & .slides-left-arrow {
    left: 0;
  }

  & .slides-right-arrow {
    right: 0;
  }

  & .slides-container {
    & .slides {
      display: flex;
      flex-direction: row;
      height: 80vh;
      overflow: hidden;

      & .slide {
        opacity: 0;
        transition: 0.3s ease;
        overflow: hidden;

        &.active {
          opacity: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          & .slide-media {
            width: 100%;
            max-height: 70%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;

            & img {
              height: 100%;
              cursor: pointer;
            }
          }

          & .slide-description {
            width: 70%;
            margin: 30px auto 10px;
            font-size: 18px;

            & b {
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  & .carousel-dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;

    & .carousel-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: styles.$light-grey;
      transition: 0.5s ease;

      &.active {
        background: styles.$grey;
      }
    }
  }
}