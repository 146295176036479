@import "constants/styles.scss";

.test-header-progress-bar {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 200px;
  align-items: flex-end;
}
