.news-video-player {
  position: relative;

  & #video-control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(lightgrey, 0.5);
    padding: 5px;

    & #volume-bar-container {
      position: absolute;
      padding: 0 5px;
      transform: rotate(-90deg);
      right: -45px;
      bottom: 90px;
    }

    & #time-start-label {
      color: black;
      font-weight: 600;
      font-size: 16px;
    }
  }
}