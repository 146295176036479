@use 'constants/styles.scss';
@use 'constants/mixins.scss';

.content-section-holder {
  display: flex;

  @media (max-width: 800px) {
    display: block;
    padding: 0 50px;
  }
}

.card-block {
  //max-width: 1024px;
  margin-bottom: 20px;

  @include mixins.phones() {
    max-width: 100vw;
    padding: 0;
  }
}

.assignment-container {
  gap: 20px;

  & > div {
    width: 50%!important;

    @include mixins.mobile() {
      width: 100%!important;
    }
  }

  @media (max-width: styles.$design-breakpoint) {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 20px;
  }}

  .assignment-wrapper {
    padding: 0 15px;
    background: styles.$white;

    border: 2px solid styles.$lightest-grey;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @include mixins.mobile() {
      width: 100vw;
      padding: 0;
    }

    & .assignment-button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px auto;
    }

    @media (max-width: styles.$design-breakpoint) {
      margin-left: 0;
    }



    .assignment-header {
      width: 90%;
      padding: 20px 0;
      border-bottom: 3px solid styles.$lightest-grey;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 14px;
      font-weight: 600;

      .assignment-status-bar {
        width: 100%;
        margin-top: 20px;
      }
    }

  }

  .assignment-input-content {
    width: 100%;
    display: flex;
    align-items: center;
    border: 2px solid styles.$lightest-grey;
    background: styles.$white;
    border-radius: 5px;
    justify-content: space-between;

    &:hover {
      border: 2px solid styles.$dark-grey !important;
    }

    &:focus {
      border: 2px solid styles.$mid-grey !important;
    }

   & .icon-container {
      width: 40px;
      height: 100%;
      border-radius: 2px;
      font-size: 13px;
      color: styles.$white;
      background: styles.$lightest-grey;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0;
    }

    & .assignment-input {
      height: 100% !important;
      text-indent: 10px !important;
      box-shadow: none !important;
      border: none !important;
      padding: 10px 10px;
      flex: 1;
    }
  }

  .assignment-info-container {
    width: 90%;
    border-bottom: 3px solid styles.$lightest-grey;
    padding-bottom: 30px;

    .info-icon-container {
      margin-right: 10px;
    }
  }

  .assignment-info-header {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .assignment-info-content {
    margin-top: 15px;
    font-size: 17px;
  }

  .assignment-initials {
    width: 100%;
    padding: 0 15px;
    margin-top: 15px;
    text-align: center;

    & .assignment-initials-header {
      margin-bottom: 15px;
      font-size: 16px;
    }
  }

  .assignment-input-error {
    color: styles.$red;
    margin-top: 10px;
  }
