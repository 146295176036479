.header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 7px 0 14px !important;
}

.to-do-list {
  padding: 0 20px !important;
}
