@use 'constants/zIndexes';
@use 'constants/styles.scss';

.toast-container {
  & .update-success {
    background-color: styles.$instructor-green;
    font-size: 16px;
  }

  & .progress-bar-success {
    background-color: styles.$white;
  }
}

.Toastify__toast {
  border-radius: 5px !important;
  font-size: 12px;
  min-height: 58px !important;
  word-wrap: anywhere;
  white-space: break-spaces;
  padding: 10px;
}

.Toastify__toast-container {
  z-index: zIndexes.$toastIndex !important;
}

.Toastify__toast--warning {
  background: styles.$orange !important;
}
