@use 'constants/styles.scss';
@use 'constants/mixins.scss';

.pagination-container {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;

  & > div {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  & .rows-container {
    flex-direction: row;
    align-items: center;
  }

  & .rows-container div {
    color: styles.$mid-grey;
  }

  @media (max-width: 1050px) {
    & .rows-container > div:first-child,
    & .rows-container > div:nth-child(3),
    & .pages-container > div:first-child {
      display: none;
    }
  }

  & .pages-array {
    margin-left: 20px;
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  & .pages-array span {
    padding: 6px 5px;
    border: 2px styles.$mid-grey solid;
    border-radius: 5px;
    margin: 0 2px;
    width: 34px;
    height: 34px;
    text-align: center;
    display: inline-block;
    cursor: pointer;

    &.selected {
      background: styles.$mid-grey;
      color: white;
    }

    &.disabled {
      cursor: auto;
      color: styles.$mid-grey;
    }
  }

  & .pages-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex: 1;

    & .page-input {
      border: 2px styles.$mid-grey solid;
      border-radius: 5px;
      padding: 8px 8px 8px 10px;
      text-align: center;
      max-width: 120px !important;
      margin: 0;

      &::placeholder {
        color: styles.$grey;
      }
    }
  }

  & .filter-select {
    box-shadow: 0 0 10px -5px black;
    width: 100px;
    color: black;
    margin: 0 20px;
  }
}

@include mixins.phones() {

  .pagination-container {
    padding: 5px 0;
    flex-wrap: wrap;

    & .filter-select {
      margin: 0;
    }

    & .pages-array {
      display: none;
    }
  }
}
