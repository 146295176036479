@use "constants/styles.scss";
@use "constants/mixins.scss";

.logout-wrapper{
  display: flex;
  align-items: center;
  min-height: 100vh;
  width: 100%;

  background-size: cover;
  @include mixins.phones() {
    display: flex;
    justify-content: center;
  }


  .login-card {
    min-height: 80vh;
    background-color: white;
    margin-left: 100px;
    padding: 65px 45px;
    display: flex;
    flex-direction: column;
    min-width: 100px;
    border-radius: 3px;
    justify-content: space-between;
    max-width: 470px;

    @include mixins.phones() {
      justify-content: space-around;
      min-height: 75vh;
      margin: 0 10px;
      padding: 40px 25px;
    }

    .login-logo {
      width: 100%;
    }

    .login-cards{
      display: flex;
      flex-direction: column;
      align-items: center;
    
      & .login-header{
        font-weight: bold;
        font-size: 33px;
        margin-bottom: 25px;
      }
    
      & .login-form{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
    
      & .login-description{
        text-align: center;
        max-width: 350px;
      }

      .login-description {
        margin-bottom: 15px;
        width: 100%;
      }
    
       .login-about {
        font-size: 14px;
        font-family: "Raleway", sans-serif;
        color: #484949;
        line-height: 25px;
      }
    
       .login-link-forgot {
        text-align: center;
        cursor: pointer;
        color: styles.$red;
        margin-bottom: 10px;
        font-size: 15px;
        text-decoration: underline;
        width: 100%;
        margin-top: 20px;
      }
    }

    .login-footer{
      width: 100%;
      padding-top: 10px;
      border-top: 1px solid styles.$mid-grey;
    
    }
    
    .login-button {
      padding: 10px 20px !important;
      height: 50px !important;
      margin: 10px 0;
    
      @include mixins.phones() {
        width: 100%;
        margin-top: 10px;
      }
    }
    
    .login-icon {
      position: absolute;
      bottom: 26px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}













