@use "constants/styles.scss";
@use "constants/mixins.scss";

.custom-table {
  border-collapse: collapse;

  & * {
    font-size: 14px;
  }

  & .custom-table-column-group {
    text-align: center;
    font-size: 1.2em;
    padding: 15px 15px 0 0;
    display: flex;
    flex-direction: row;

    & > div > div {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;

      & .info-icon-container {
        margin-left: 5px;
      }
    }
  }

  & .custom-table-header {
    text-align: center;
    font-size: 1.2em;
    border-bottom: 1px black solid;
    padding: 5px 15px 15px 0;


    & > div {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      text-align: center;
      word-wrap: break-word;
      font-weight: normal !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      justify-content: center;

      & .column-header-arrow {
        margin-left: 5px;
        height: 20px;
        width: 20px;
      }
    }
  }

  & .custom-table-body {
    width: 100%;
    overflow: auto;
    height: 400px;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px black solid;
  }

  & .custom-table-row,
  & .custom-table-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  & .custom-table-row > div {
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  & .custom-table-row {
    padding: 5px 0;

    &.selected {
      background-color: styles.$selection-blue;
    }

    &:hover {
      background-color: styles.$light-grey;
    }
  }

  & .checkbox-column {
    width: 40px;
    padding: 2px 10px !important;
    cursor: pointer;
  }
}

.item-template-list-categories {
  font-size: 0.8em;
}

@include mixins.phones() {
  .custom-table {

    & .custom-table-body {
      height: 250px;
    }

    & .custom-table-row {
      width: 600px;
    }
  }
}


@media (max-height: 940px) {
  .custom-table {
    & .custom-table-body {
      height: 300px;
    }
  }
}

@media (max-height: 770px) {
  .custom-table {
    & .custom-table-body {
      height: 200px;
    }
  }
}

@media (max-width: 430px) {
  .custom-table {
    & .custom-table-body {
      height: 180px;
    }
  }
}