#dashboard-content {

  & .weaknesses {
    position: relative;
  }
}

.dashboard-news-button {
  width: 100%;
  max-height: 35px;

  & > span {
    font-size: 12px;
  }
}

.dashboard-news-tab {
  padding-bottom: 50px;
  max-height: calc(100% - 50px) !important;

  & .carousel-container {
    max-height: calc(100% - 50px) !important;
    margin-bottom: 40px;

    .slides-container {
      max-height: calc(100% - 500px) !important;

      & .slides {
        height: 100% !important;

        & .slide-media img {
          max-height: 50vh;
        }
      }
    }
  }

  & .slide-title {
    background: none;
    color: black;
  }
}
