@use "constants/styles";
@use "constants/zIndexes";
@use "constants/mixins";

.header-tab {
  position: relative;
  bottom: -1px;
  color: styles.$grey;
  font-size: 14px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 5px 15px;
  background-color: styles.$white;
  margin-left: 3px;
  cursor: pointer;
  transition-property: background, color;
  transition-duration: 0.2s;
  display: flex;
  flex-direction: row;
  border-color: styles.$light-grey;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  opacity: 0.7;
  max-width: 300px;
  flex-wrap: nowrap;
  font-weight: 400;

  &:not(.disabled-tab):hover {
    background-color: styles.$white;
    color: styles.$black;
  }
}

.header-tab:first-child {
  margin-left: 0;
}

.active-tab {
  background-color: styles.$white;
  color: styles.$black;
  padding: 7px 15px;
  bottom: 0;
  border-width: 2px 2px 0 2px;
  border-color: styles.$light-grey;
  border-style: solid;
  opacity: 1;
  z-index: zIndexes.$calendarHeaderIndexes;
}

.disabled-tab {
  background-color: styles.$light-grey;
  color: styles.$white;
  cursor: default;
  pointer-events: none;
}

@include mixins.phones() {
  .headerTab {
    bottom: -3px;
  }
}
