@use "constants/styles.scss";


.dropdown-container {
    width: 25px;
    height: 23px;
    margin-bottom: 5px;
    border: 1px solid styles.$lightest-grey;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all ease-in-out 0.2s;
    background: styles.$dark-grey;

    @media (max-width: 992px) {
      right: 3px;
    }

    &--withWorkbook {
      right: 15px;
    }

    &--expanded {
      transform: rotate(180deg);
      color: styles.$white;
      background: styles.$dark-grey;
    }
  }
