@import "constants/styles.scss";

.table-no-data-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  font-weight: 500;

  color: $mid-grey;

}